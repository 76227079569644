<template>
<div class="footerSection">
    <!-- Footer -->
    <div class="footer">
        <div class="container">
            <div class="row">
                <div class="col-md-4">
                    <div class="footer-col">
                        <h4>About Ready eShop</h4>
                        <p>Ready eShop is an e-commerce service platform.
                            This allows you to create and host the best e-commerce website in the shortest time, at the lowest cost.</p>
                    </div>
                </div> <!-- end of col -->
                <div class="col-md-8">
                    <div class="footer-col middle">
                        <!-- <h4>Important Links</h4> -->
                        <div class="importantLinks d-flex justify-content-between">
                            <ul class="list-unstyled li-space-lg">
                                <li class="media">
                                    <i class="fas fa-square"></i>
                                    <div class="media-body">
                                        <router-link to="pricing">Pricing</router-link>
                                    </div>
                                </li>
                                <li class="media">
                                    <i class="fas fa-square"></i>
                                    <div class="media-body">
                                        <router-link to="clients">Clients</router-link>
                                    </div>
                                </li>
                                <li class="media">
                                    <i class="fas fa-square"></i>
                                    <div class="media-body">
                                        <router-link to="features">Features</router-link>
                                    </div>
                                </li>
                                <li class="media">
                                    <i class="fas fa-square"></i>
                                    <div class="media-body">
                                        <router-link to="about">About</router-link>
                                    </div>
                                </li>
                            </ul>
                            <ul class="list-unstyled li-space-lg">
                                <li class="media">
                                    <i class="fas fa-square"></i>
                                    <div class="media-body">
                                        <router-link to="control-dashboard">Dashboard</router-link>
                                    </div>
                                </li>
                                <li class="media">
                                    <i class="fas fa-square"></i>
                                    <div class="media-body">
                                        <router-link to="faq">FAQ</router-link>
                                    </div>
                                </li>
                                <li class="media">
                                    <i class="fas fa-square"></i>
                                    <div class="media-body"><a target="_blank" href="https://blog.readyeshop.com/">Blog</a></div>
                                </li>
                                <li class="media">
                                    <i class="fas fa-square"></i>
                                    <div class="media-body">
                                        <router-link to="news">News</router-link>
                                    </div>
                                </li>
                            </ul>
                            <ul class="list-unstyled li-space-lg">
                                <li class="media">
                                    <i class="fas fa-square"></i>
                                    <div class="media-body">
                                        <router-link to="contact">Help</router-link>
                                    </div>
                                </li>
                                <li class="media">
                                    <i class="fas fa-square"></i>
                                    <div class="media-body">
                                        <router-link to="free-tools">Tools</router-link>
                                    </div>
                                </li>
                                <li class="media">
                                    <i class="fas fa-square"></i>
                                    <div class="media-body">
                                        <router-link to="control-shipping">Shipping</router-link>
                                    </div>
                                </li>
                                <li class="media">
                                    <i class="fas fa-square"></i>
                                    <div class="media-body">
                                        <router-link to="control-payment">Payment</router-link>
                                    </div>
                                </li>
                            </ul>
                            <ul class="list-unstyled li-space-lg">
                                <li class="media">
                                    <i class="fas fa-square"></i>
                                    <div class="media-body">
                                        <router-link to="create-your-own-brand">Branding</router-link>
                                    </div>
                                </li>
                                <li class="media">
                                    <i class="fas fa-square"></i>
                                    <div class="media-body">
                                        <router-link to="convert-your-domain">Domain</router-link>
                                    </div>
                                </li>
                                <li class="media">
                                    <i class="fas fa-square"></i>
                                    <div class="media-body">
                                        <router-link to="social-media">Media</router-link>
                                    </div>
                                </li>
                                <li class="media">
                                    <i class="fas fa-square"></i>
                                    <div class="media-body">
                                        <router-link to="sms-email">SMS</router-link>
                                    </div>
                                </li>
                            </ul>
                            <ul class="list-unstyled li-space-lg">
                                <li class="media">
                                    <i class="fas fa-square"></i>
                                    <div class="media-body">
                                        <router-link to="product-category">Product</router-link>
                                    </div>
                                </li>
                                <li class="media">
                                    <i class="fas fa-square"></i>
                                    <div class="media-body">
                                        <router-link to="product-photography">Photography</router-link>
                                    </div>
                                </li>
                                <li class="media">
                                    <i class="fas fa-square"></i>
                                    <div class="media-body">
                                        <router-link to="contact">Contact</router-link>
                                    </div>
                                </li>
                                <li class="media">
                                    <i class="fas fa-square"></i>
                                    <div class="media-body">
                                        <router-link to="market-place">Sale</router-link>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div> <!-- end of col -->
            </div> <!-- end of row -->
        </div> <!-- end of container -->
    </div> <!-- end of footer -->
    <!-- end of footer -->

    <!-- Copyright -->
    <div class="copyright">
        <div class="container border-top">
            <div class="row">
                <div class="col-lg-6">
                    <p class="p-small" style="color:#333">Copyright © 2021 <a href="https://readyeshop.com/">Readyeshop</a> - All rights reserved</p>
                </div> <!-- end of col -->
                <div class="col-lg-6 text-center">
                    <div class="footersocial pt-3">
						<a target="_blank" href="https://www.facebook.com/readyeshop.xyz" class="single-social" style="background-color: #1771e6;">
							<i class="fab fa-facebook-f"></i>
						</a>
						<a target="_blank" href="https://twitter.com/Ready_eShop" class="single-social" style="background-color: #1c9cea;">
							 <i class="fab fa-twitter"></i>
						</a>
						<a target="_blank" href="https://www.youtube.com/channel/UCrAl2ODEJo9_VhgKAAgeDXw" class="single-social" style="background-color: #f70000;">
							<i class="fab fa-youtube "></i>
						</a>
						<a target="_blank" href="https://www.instagram.com/ready_eshop/" class="single-social" style="background-color: #a91c82;">
							<i class="fab fa-instagram "></i>
						</a>
						<a target="_blank" href="https://www.linkedin.com/" class="single-social" style="background-color: #0073b1;">
							<i class="fab fa-linkedin-in "></i>
						</a>
                    </div>
                </div> <!-- end of col -->
            </div> <!-- enf of row -->
        </div> <!-- end of container -->
    </div> <!-- end of copyright -->
    <!-- end of copyright -->
</div>
</template>

<script>
export default {
    data() {
        return {}
    },
    created() {

    },
    methods: {

    }
}
</script>
