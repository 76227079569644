<template>
	<div>
		<Navbar />
		<!-- Header -->
		<header id="header" class="header">
			<div class="header-content">
				<div class="container">
					<div class="row">
						<div class="col-lg-6">
							<div class="text-container">
								<h1><span class="turquoise d-block">Your eShop </span> A Click Away</h1>
								<p class="p-large">The whole process is so easy and intutitive that you can start
									selling your products right away.</p>
								<router-link class="btn-solid-lg page-scroll" to="try-free">FREE TRIAL</router-link>
							</div> <!-- end of text-container -->
						</div> <!-- end of col -->
						<div class="col-lg-6">
							<div class="image-container welcome-right-image">
								<img class="img-fluid" src="images/Your_eShop_a_Click_Away.svg" alt="alternative">
							</div>
						</div> <!-- end of col -->
					</div> <!-- end of row -->
				</div> <!-- end of container -->
			</div> <!-- end of header-content -->
		</header> <!-- end of header -->
		<!-- end of header -->

		<!-- Details 1 -->
		<div class="basic-1">
			<div class="container">
				<div class="row">
					<div class="col-lg-12 text-center">
						<h2>Ready E-Commerce Platform</h2>
						<p class="p-heading p-large text-dark"> Create eShop, Start Your Online Business
						</p>
					</div>
				</div>
				<div class="row">
					<div class="col-lg-6">
						<div class="text-container">
							<h2>Ready Site</h2>
							<p>Your E-commerce site is fully Ready to start your business. The site is a complete
								package with all the required features like Product Management, Check Out, Online
								Payment Gateway and many more features.</p>
							<router-link to="try-free" class="btn-arrow">Free Trial <i class="fas fa-caret-right"></i>
							</router-link>
						</div>
					</div>
					<div class="col-lg-6">
						<div class="image-container">
							<img class="img-fluid" src="images/Ready_Site.svg" style="max-height: 280px; width: 334px"
								alt="alternative">
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-lg-6">
						<div class="image-container">
							<img class="img-fluid" src="images/Dynamic_Design.svg"
								style="max-height: 280px; width: 334px" alt="alternative">
						</div>
					</div>
					<div class="col-lg-6">
						<div class="text-container">
							<h2>Dynamic Design</h2>
							<p>The site has developed with such a dynamic way that you can change and update all of the
								component of the site yourself with minimum basic knowledge.</p>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-lg-6">
						<div class="text-container">
							<h2>Easy Management</h2>
							<p>Management part or admin panel of an E-commerce site is the most critical part of the
								online business. In Ready Eshop this is very simple and user friendly. You can manage
								your E-commerce site without help of any technical person.</p>
						</div>
					</div>
					<div class="col-lg-6">
						<div class="image-container">
							<img class="img-fluid" src="images/Easy_Management.svg"
								style="max-height: 280px; width: 334px" alt="alternative">
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-lg-6">
						<div class="image-container">
							<img class="img-fluid" src="images/Design And Plan.svg"
								style="max-height: 280px; width: 334px" alt="alternative">
						</div>
					</div>
					<div class="col-lg-6">
						<div class="text-container">
							<h2>Secure E-commerce Design</h2>
							<ul class="list-unstyled li-space-lg">
								<li class="media">
									<i class="fas fa-check"></i>
									<div class="media-body">Developed in Latest and secure Laravel Frame work along with
										vue.js</div>
								</li>
								<li class="media">
									<i class="fas fa-check"></i>
									<div class="media-body">ISO 27001:2013 Information Security Management System
										Standard</div>
								</li>
								<li class="media">
									<i class="fas fa-check"></i>
									<div class="media-body">Secure SSL Certificate installed</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-lg-6">
						<div class="text-container">
							<h2>Dynamic SEO</h2>
							<ul class="list-unstyled li-space-lg">
								<li class="media">
									<i class="fas fa-check"></i>
									<div class="media-body">Meta Title, Keywork and Description of Page, Category and
										Products has been included in the site dynamically.</div>
								</li>
								<li class="media">
									<i class="fas fa-check"></i>
									<div class="media-body">Google Analytic Support</div>
								</li>
								<li class="media">
									<i class="fas fa-check"></i>
									<div class="media-body">Google Search Console Support</div>
								</li>
							</ul>
							<router-link to="try-free" class="btn-arrow">Free Trial <i class="fas fa-caret-right"></i>
							</router-link>
						</div>
					</div>
					<div class="col-lg-6">
						<div class="image-container">
							<img class="img-fluid" src="images/Optimizing.svg" style="max-height: 280px; width: 334px"
								alt="alternative">
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- end of details 1 -->

		<div class="how_it_work mt-5">
			<div class="container">
				<div class="row">
					<div class="col-lg-12 text-center">
						<h2>How to Create Your eShop</h2>
					</div>
				</div>
				<div class="row">
					<div class="col-lg-1"></div>
					<div class="col-lg-2">
						<div class="single_step step1 step_image_down">
							<img src="images/How_it_Work_1.png" alt="">
							<h3>Step 1</h3>
							<p>Go To Our Home Site</p>
						</div>
					</div>
					<div class="col-lg-2">
						<div class="single_step step2 step_image_up">
							<img src="images/How_it_Work_2.png" alt="">
							<h3>Step 2</h3>
							<p>Click On "Free Trial"</p>
						</div>
					</div>
					<div class="col-lg-2">
						<div class="single_step step3 step_image_down">
							<img src="images/How_it_Work_3.png" alt="">
							<h3>Step 3</h3>
							<p>Verify Your Identity Via Mobile or Email</p>
						</div>
					</div>
					<div class="col-lg-2">
						<div class="single_step step4 step_image_up">
							<img src="images/How_it_Work_4.png" alt="">
							<h3>Step 4</h3>
							<p>In The New Form Type Your Name, Business Name, Domain Name Or Sub-domain Name, Select
								Business Type And Submit The Form</p>
						</div>
					</div>
					<div class="col-lg-2">
						<div class="single_step step5 step_image_down">
							<img src="images/How_it_Work_5.png" alt="">
							<h3>Step 5</h3>
							<p>Finally Set-up Your Website From Admin Panel And Go On</p>
						</div>
					</div>
				</div>
			</div>
		</div>


		<!-- Pricing -->
		<div id="pricing" class="cards-2">
			<div class="container">
				<div class="row">
					<div class="col-lg-12 text-center">
						<h2>Pricing</h2>
					</div>
				</div>
				<div class="row">
					<div class="col-lg-12">
						<!-- Card-->
						<div class="card">
							<div class="card-body"
								style="background-image: linear-gradient(to right bottom, #9b9b9b, #a9a9a9, #b7b7b7, #c5c5c5, #d3d3d3, #d4d4d5, #d6d6d6, #d7d7d8, #cbcbcd, #bfc0c2, #b4b4b8, #a8a9ad);">
								<div class="card-title text-uppercase">Silver</div>
								<div class="card-subtitle">Just to see what can be achieved</div>
								<hr class="cell-divide-hr">
								<div class="price">
									<span class="currency">৳</span><span class="value">500</span>
									<div class="frequency">monthly</div>
								</div>
								<hr class="cell-divide-hr">
								<ul class="list-unstyled li-space-lg">
									<li class="media">
										<i class="fas fa-check"></i>
										<div class="media-body">Connect Own Domain</div>
									</li>
									<li class="media">
										<i class="fas fa-check"></i>
										<div class="media-body">Trusted SSL</div>
									</li>
									<li class="media">
										<i class="fas fa-times"></i>
										<div class="media-body">Payment Gateway</div>
									</li>
									<li class="media">
										<i class="fas fa-check"></i>
										<div class="media-body">Product: 50</div>
									</li>
									<li class="media">
										<i class="fas fa-times"></i>
										<div class="media-body">Manager Account</div>
									</li>
								</ul>
								<div class="button-wrapper">
									<router-link to="try-free" class="btn-solid-reg page-scroll">FREE TRIAL
									</router-link>
								</div>
							</div>
						</div> <!-- end of card -->
						<!-- end of card -->

						<!-- Card-->
						<div class="card">
							<div class="label">
								<p class="best-value">Best Value</p>
							</div>
							<div class="card-body"
								style="background-image: linear-gradient(to right bottom, #a38625, #b39938, #c2ac4a, #d2c05d, #e2d470, #e6d773, #eadb75, #eede78, #e7d16b, #e0c55f, #d9b853, #d2ac47);">
								<div class="card-title text-uppercase">Gold</div>
								<div class="card-subtitle">Very appropriate for the short term</div>
								<hr class="cell-divide-hr">
								<div class="price">
									<span class="currency">৳</span><span class="value">2500</span>
									<div class="frequency">monthly</div>
								</div>
								<hr class="cell-divide-hr">
								<ul class="list-unstyled li-space-lg">
									<li class="media">
										<i class="fas fa-check"></i>
										<div class="media-body">Connect Own Domain</div>
									</li>
									<li class="media">
										<i class="fas fa-check"></i>
										<div class="media-body">Trusted SSL</div>
									</li>
									<li class="media">
										<i class="fas fa-check"></i>
										<div class="media-body">Payment Gateway</div>
									</li>
									<li class="media">
										<i class="fas fa-check"></i>
										<div class="media-body">Product: 500</div>
									</li>
									<li class="media">
										<i class="fas fa-check"></i>
										<div class="media-body">Manager Account: 2</div>
									</li>
								</ul>
								<div class="button-wrapper">
									<router-link to="try-free" class="btn-solid-reg page-scroll">FREE TRIAL
									</router-link>
								</div>
							</div>
						</div> <!-- end of card -->
						<!-- end of card -->

						<!-- Card-->
						<div class="card">
							<div class="card-body"
								style="background-image: linear-gradient(to right bottom, #c3c3c3, #cacbd0, #cfd4dd, #d1dee9, #d1e8f4, #d1e8f4, #d1e8f4, #d1e8f4, #d1dee9, #cfd4dd, #cacbd0, #c3c3c3);">
								<div class="card-title text-uppercase">Platinum</div>
								<div class="card-subtitle">Must have for large companies</div>
								<hr class="cell-divide-hr">
								<div class="price">
									<span class="value" style="font-size: 25px">
										<router-link to="contact">Call for Price</router-link>
									</span>
									<div class="frequency">monthly</div>
								</div>
								<hr class="cell-divide-hr">
								<ul class="list-unstyled li-space-lg">
									<li class="media">
										<i class="fas fa-check"></i>
										<div class="media-body">Connect Own Domain</div>
									</li>
									<li class="media">
										<i class="fas fa-check"></i>
										<div class="media-body">Trusted SSL</div>
									</li>
									<li class="media">
										<i class="fas fa-check"></i>
										<div class="media-body">Payment Gateway</div>
									</li>
									<li class="media">
										<i class="fas fa-check"></i>
										<div class="media-body">Product: Unlimited</div>
									</li>
									<li class="media">
										<i class="fas fa-check"></i>
										<div class="media-body">Manager Account: 10</div>
									</li>
								</ul>
								<div class="button-wrapper">
									<router-link to="try-free" class="btn-solid-reg page-scroll">FREE TRIAL
									</router-link>
								</div>
							</div>
						</div> <!-- end of card -->
						<!-- end of card -->

					</div> <!-- end of col -->
				</div> <!-- end of row -->
			</div> <!-- end of container -->
		</div> <!-- end of cards-2 -->
		<!-- end of pricing -->


		<div class="why_choose_section">
			<div class="container">
				<div class="row">
					<div class="col-lg-8 offset-lg-2 text-center">
						<h2>Why People Choose Ready eShop</h2>
						<p>With the ever-increasing use of the internet and its popularity among all demographic
							segments, electronic commerce is by all means the way to go for virtually all businesses.
							Creating an Online Shop means that a business owner or company gain a significant authority
							in the online marketplace. </p>
					</div>
				</div>
			</div>
			<div class="container mt-4">
				<div class="row">
					<div class="col-lg-4 text-center">
						<div class="singleChoose">
							<h4>Empowering Customers</h4>
							<p>E-commerce has gone a long way in empowering consumers through the internet. Through
								their
								websites and online advertisements, businesses can communicate with their clients,
								provide
								information on new products and complete sales. </p>
						</div>
					</div>
					<div class="col-lg-4 text-center">
						<div class="singleChoose">
							<h4>Increased Efficiency</h4>
							<p>Ecommerce has helped businesses improve their efficiency through streamlining the
								production
								and service delivery process. E-commerce has simplified sales and marketing as
								salespeople
								can complete sales online, saving time, increasing revenue and enhancing efficiency.</p>
						</div>
					</div>
					<div class="col-lg-4 text-center">
						<div class="singleChoose">
							<h4>Exploring New Businesses</h4>
							<p>E-commerce enables new and existing businesses to venture into the market and reach
								potential
								customer without the need for physical presence. This way, business organizations can
								create
								products, avail them on their websites and other electronic portals and make sales
								through
								online transactions.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="recommended_for mt-5 mb-5">
			<div class="container">
				<div class="row mb-3">
					<div class="col-lg-6 offset-lg-3 text-center">
						<h2>Recommended For</h2>
					</div>
				</div>
				<div class="row">
					<div class="col-lg-2">
						<div class="recommend_box">
							<img src="images/Grocery_Shop.png" alt="">
							<h3>Grocery Shop</h3>
						</div>
					</div>
					<div class="col-lg-2">
						<div class="recommend_box">
							<img src="images/Fashion_Beauty_Shop.png" alt="">
							<h3>
								Beauty Shop
							</h3>
						</div>
					</div>
					<div class="col-lg-2">
						<div class="recommend_box">
							<img src="images/Food_Store.png" alt="">
							<h3>
								Food Store
							</h3>
						</div>
					</div>
					<div class="col-lg-2">
						<div class="recommend_box">
							<img src="images/Electronics_Store.png" alt="">
							<h3> Electronics Store</h3>
						</div>
					</div>
					<div class="col-lg-2">
						<div class="recommend_box">
							<img src="images/Baby_&_Toy_Store.png" alt="">
							<h3>
								Baby & Toy Store
							</h3>
						</div>
					</div>
					<div class="col-lg-2">
						<div class="recommend_box">
							<img src="images/Dress_Store.png" alt="">
							<h3>
								Fashion Store
							</h3>
						</div>
					</div>
				</div>
			</div>
		</div>


		<!-- Request -->
		<!--		<div id="request" class="form-1">-->
		<!--			<div class="container">-->
		<!--				<div class="row">-->
		<!--					<div class="col-lg-6">-->
		<!--						<div class="text-container">-->
		<!--							<h2>Fill The Following Form To Request A Demo</h2>-->
		<!--							<p>ReadyeShop is one of the easiest and feature packed ecommerce service platform in the-->
		<!--								market. Discover what it can do for your business organization right away.</p>-->
		<!--							<ul class="list-unstyled li-space-lg">-->
		<!--								<li class="media">-->
		<!--									<i class="fas fa-check"></i>-->
		<!--									<div class="media-body"><strong class="blue">Automate your online business</strong>-->
		<!--										activities and get results today</div>-->
		<!--								</li>-->
		<!--								<li class="media">-->
		<!--									<i class="fas fa-check"></i>-->
		<!--									<div class="media-body"><strong class="blue">Interact with all your</strong>-->
		<!--										targeted customers at a personal level</div>-->
		<!--								</li>-->
		<!--								<li class="media">-->
		<!--									<i class="fas fa-check"></i>-->
		<!--									<div class="media-body"><strong class="blue">Convince them to buy</strong> your-->
		<!--										company's awesome products</div>-->
		<!--								</li>-->
		<!--								<li class="media">-->
		<!--									<i class="fas fa-check"></i>-->
		<!--									<div class="media-body"><strong class="blue">Save precious time</strong> and invest-->
		<!--										it where you need it the most</div>-->
		<!--								</li>-->
		<!--							</ul>-->
		<!--						</div> -->
		<!--					</div> -->
		<!--					<div class="col-lg-6">-->
		<!--            -->
		<!--						<div class="form-container">-->
		<!--							<form id="meetingRequestForm" @submit.prevent="meetingSubmit($event)">-->
		<!--								<div class="form-group">-->
		<!--									<input type="text" class="form-control-input" placeholder="Full name"-->
		<!--										v-model="meetingForm.name" required>-->
		<!--								</div>-->
		<!--								<div class="form-group">-->
		<!--									<input type="email" class="form-control-input" placeholder="Email"-->
		<!--										v-model="meetingForm.email" required>-->
		<!--								</div>-->
		<!--								<div class="form-group">-->
		<!--									<input type="text" class="form-control-input" placeholder="Phone"-->
		<!--										v-model="meetingForm.phone" required>-->
		<!--								</div>-->
		<!--								<div class="form-group">-->
		<!--									<select class="form-control-select" v-model="meetingForm.interested" required>-->
		<!--										<option class="select-option" value="" disabled selected>Interested in...-->
		<!--										</option>-->
		<!--										<option class="select-option" value="starter">Starter</option>-->
		<!--										<option class="select-option" value="medium">Medium</option>-->
		<!--										<option class="select-option" value="complete">Complete</option>-->
		<!--									</select>-->
		<!--								</div>-->
		<!--								<div class="form-group checkbox">-->
		<!--									<input type="checkbox" value="Agreed-to-Terms" name="rterms" required>I agree with-->
		<!--									Readyeshop's stated <a href="privacy-policy.html">Privacy Policy</a> and <a-->
		<!--										href="terms-conditions.html">Terms & Conditions</a>-->
		<!--									<div class="help-block with-errors"></div>-->
		<!--								</div>-->
		<!--								<div class="form-group">-->
		<!--									<button type="submit" class="form-control-submit-button">REQUEST</button>-->
		<!--								</div>-->
		<!--							</form>-->
		<!--						</div> -->


		<!--					</div> -->
		<!--				</div> -->
		<!--			</div> -->
		<!--		</div> -->
		<!-- end of request -->

		<!-- Video -->
		<!-- <div class="basic-3 mt-3">
			<div class="container">
				<div class="row">
					<div class="col-lg-12">
						<h2>Check Out The Video</h2>
					</div> 
				</div> 
				<div class="row">
					<div class="col-lg-12">
						<div class="image-container">
							<div class="video-wrapper">
								<a class="popup-youtube" href="javascript:void(0)" data-effect="fadeIn">
									<img class="img-fluid" src="images/Check_Out_The_Video.svg" alt="alternative">
								</a>
								<div v-if="showVideoModal">
									<transition name="modal">
										<div class="modal-mask">
											<div class="modal-wrapper">
												<div class="modal-dialog modal-lg" role="document">
													<div class="modal-content">
														<div class="modal-header">
															<h5 class="modal-title">How to Setup Your E-commerce Site in
																Ready eShop</h5>
															<button type="button" class="close" data-dismiss="modal"
																aria-label="Close">
																<span aria-hidden="true"
																	@click="showVideoModal = false">&times;</span>
															</button>
														</div>
														<div class="modal-body" style="padding: 0px">
															<iframe style="width: 100%;" width="800" height="400"
																src="https://www.youtube.com/embed/ANUo6aVCHJE"
																title="YouTube video player" frameborder="0"
																allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
																allowfullscreen></iframe>
														</div>
													</div>
												</div>
											</div>
										</div>
									</transition>
								</div>
								<span style="cursor: pointer;" @click="showVideoModal = true" class="video-play-button">
									<span></span>
								</span>
							</div>
						</div> 
					</div>
				</div>
			</div> 
		</div> -->
		<!-- end of video -->

		<!-- Testimonials -->
		<div class="slider-2">
			<div class="container">
				<div class="row">
					<div class="col-lg-6">
						<div class="image-container">
							<img class="img-fluid" src="images/Testimonials.svg" alt="alternative">
						</div> <!-- end of image-container -->
					</div> <!-- end of col -->
					<div class="col-lg-6">
						<h2>Testimonials</h2>
						<!-- Card Slider -->
						<swiper class="swiper" :options="swiperOptionForTestimonial">
							<swiper-slide v-for="testimonial in testimonials" :key="testimonial.id">
								<div class="card">
									<img class="card-image" :src="testimonialImgUrl + testimonial.image"
										alt="alternative">
									<div class="card-body">
										<p class="testimonial-text">{{  testimonial.testimonial_text  }}</p>
										<p class="testimonial-author">{{  testimonial.testimonial_author  }}</p>
									</div>
								</div>
							</swiper-slide>
							<div class="swiper-button-prev" slot="button-prev"></div>
							<div class="swiper-button-next" slot="button-next"></div>
						</swiper>
						<!-- end of card slider -->
					</div> <!-- end of col -->
				</div> <!-- end of row -->
			</div> <!-- end of container -->
		</div> <!-- end of slider-2 -->
		<!-- end of testimonials -->
		<!-- Customers -->
		<div class="slider-1">
			<div class="container">
				<div class="row">
					<div class="col-lg-12">
						<h5>Trusted By</h5>
						<swiper class="swiper" :options="swiperOptionForClientLogos">
							<swiper-slide v-for="trustLogo in all_trusted_logos" :key="trustLogo.id">
								<div class="image-container">
									<a :href="'https://' + trustLogo.url" target="_blank">
										<img class="img-responsive" :src="trustedimgUrl + trustLogo.logo">
									</a>
								</div>
							</swiper-slide>
						</swiper>
						<!-- end of image slider -->
					</div> <!-- end of col -->
				</div> <!-- end of row -->
			</div> <!-- end of container -->
		</div> <!-- end of slider-1 -->
		<!-- end of customers -->
		<!-- About -->
		<!--		<div id="about" class="basic-4">-->
		<!--			<div class="container">-->
		<!--				<div class="row">-->
		<!--					<div class="col-lg-12">-->
		<!--						<h2>About The Team</h2>-->
		<!--						<p class="p-heading p-large">Meat our team of specialized marketers and business developers-->
		<!--							which will help you research new products and launch them in new emerging markets</p>-->
		<!--					</div> -->
		<!--				</div>-->
		<!--				<div class="row">-->
		<!--					<div class="col-lg-12">-->
		<!--						<div class="team-member" v-for="team in teams" :key="team.id">-->
		<!--							<div class="image-wrapper">-->
		<!--								<img style="width: 200px; height: 200px;" class="img-fluid" :src="teamImgUrl+team.image"-->
		<!--									alt="alternative">-->
		<!--							</div> -->
		<!--							<p class="p-large"><strong>{{ team.name }}</strong></p>-->
		<!--							<p class="job-title">{{ team.designation }}</p>-->
		<!--							<span class="social-icons">-->
		<!--								<span class="fa-stack">-->
		<!--									<a href="#your-link">-->
		<!--										<i class="fas fa-circle fa-stack-2x facebook"></i>-->
		<!--										<i class="fab fa-facebook-f fa-stack-1x"></i>-->
		<!--									</a>-->
		<!--								</span>-->
		<!--								<span class="fa-stack">-->
		<!--									<a href="#your-link">-->
		<!--										<i class="fas fa-circle fa-stack-2x twitter"></i>-->
		<!--										<i class="fab fa-twitter fa-stack-1x"></i>-->
		<!--									</a>-->
		<!--								</span>-->
		<!--							</span>-->
		<!--						</div> -->
		<!--						-->

		<!--					</div>-->
		<!--				</div> -->
		<!--			</div> -->
		<!--		</div> -->
		<!-- end of about -->

		<!-- Contact -->
		<div id="contact" class="form-2">
			<div class="container">
				<div class="row">
					<div class="col-lg-12">
						<h2>Contact Information</h2>
						<ul class="list-unstyled li-space-lg">
							<li class="address">Don't hesitate to give us a call or send us a contact form message</li>
							<li><i class="fas fa-map-marker-alt"></i>20/2, West Panthopath, 2nd Floor Dhaka-1205,
								Bangladesh</li>
							<li><i class="fas fa-phone"></i><a class="turquoise"
									href="tel:003024630820">+8809666910441</a></li>
							<li><i class="fas fa-envelope"></i><a class="turquoise"
									href="mailto:helpdesk@readyeshop.com">helpdesk@readyeshop.com</a></li>
						</ul>
					</div> <!-- end of col -->
				</div> <!-- end of row -->
				<div class="row">
					<div class="col-lg-6">
						<div class="map-responsive">
							<iframe
								src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14607.41469288731!2d90.3827273!3d23.7525969!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfabfcc3ea2a8ada!2sNextech%20Limited!5e0!3m2!1sen!2sbd!4v1603351887760!5m2!1sen!2sbd"
								allowfullscreen></iframe>
						</div>
					</div> <!-- end of col -->
					<div class="col-lg-6">

						<!-- Contact Form -->
						<form id="contactForm" @submit.prevent="contactSubmit($event)">
							<div class="row">
								<div class="col-lg-6">
									<div class="form-group">
										<input type="text" class="form-control-input" placeholder="First Name"
											v-model="contactForm.fname" required>
									</div>
								</div>
								<div class="col-lg-6">
									<div class="form-group">
										<input type="text" class="form-control-input" placeholder="Mobile"
											v-model="contactForm.mobile" required>
									</div>
								</div>
							</div>
							<div class="form-group">
								<input type="email" class="form-control-input" v-model="contactForm.email"
									placeholder="Email" required>
							</div>
							<div class="form-group">
								<input type="text" class="form-control-input" placeholder="Subject"
									v-model="contactForm.subject">
							</div>
							<div class="form-group">
								<textarea class="form-control-textarea" placeholder="Message" v-model="contactForm.body"
									required></textarea>
							</div>
							<div class="form-group checkbox">
								<input type="checkbox" id="cterms" value="Agreed-to-Terms" required>I have read and
								agree with ReadyeShop's stated <a href="privacy-policy.html">Privacy Policy</a> and <a
									href="terms-conditions.html">Terms Conditions</a>
							</div>
							<div class="form-group">
								<button type="submit" class="form-control-submit-button">SUBMIT MESSAGE</button>
							</div>
							<div class="form-message">
								<div id="cmsgSubmit" class="h3 text-center hidden"></div>
							</div>
						</form>
						<!-- end of contact form -->

					</div> <!-- end of col -->
				</div> <!-- end of row -->
			</div> <!-- end of container -->
		</div> <!-- end of form-2 -->
		<!-- end of contact -->

		<Footer />
	</div>
</template>

<script>
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import {
	Swiper,
	SwiperSlide
} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
	data() {
		return {
			contactForm: {},
			meetingForm: {
				interested: "",
			},
			showVideoModal: false,
			teams: [],
			testimonials: [],
			all_trusted_logos: [],
			teamImgUrl: "",
			testimonialImgUrl: "",
			trustedimgUrl: "",
			meta: {},
			swiperOptionForTestimonial: {
				loop: true,
				autoplay: {
					delay: 5000,
				},
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev'
				}
			},
			swiperOptionForClientLogos: {
				slidesPerView: 5,
				spaceBetween: 20,
				loop: true,
				loopFillGroupWithBlank: true,
				autoplay: {
					delay: 2000,
				},
			},
		};
	},
	components: {
		Navbar,
		Footer,
		Swiper,
		SwiperSlide
	},
	mounted() {

	},
	created() {
		this.getHomeData();
	},
	methods: {
		async getHomeData() {
			let dataObj = {
				'meta_page': 'home',
				'status': '1',
			};
			let allData = await this.$root.getFrontData(dataObj, `/api/home_data`);
			this.meta = allData.data.metatags[0]
			this.trustedimgUrl = allData.data.ShopUserUrl;
			this.all_trusted_logos = allData.data.ShopUsers.data;
			this.teams = allData.data.teams
			this.teamImgUrl = allData.data.teamUrl
			this.testimonials = allData.data.testimonials
			this.testimonialImgUrl = allData.data.testimonialUrl
		},
		contactSubmit() {
			var axios = require('axios');
			var qs = require('qs');
			var data = qs.stringify(this.contactForm);
			var config = {
				method: 'post',
				url: `${this.$store.state.hostBase}/api/contactus`,
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded'
				},
				data: data
			};

			axios(config)
				.then(function (response) {
					Vue.$toast.open({
						message: response.data.message,
						position: "top-right",
						type: "success",
						duration: 3000,
						dismissible: false,
					});
				})
				.catch(function (error) {
					Vue.$toast.open({
						message: error.data.message,
						position: "top-right",
						type: "error",
						duration: 3000,
						dismissible: false,
					});
				});
		},
		meetingSubmit() {
			var axios = require('axios');
			var qs = require('qs');
			var data = qs.stringify(this.meetingForm);
			var config = {
				method: 'post',
				url: `${this.$store.state.hostBase}/api/meeting_request`,
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded'
				},
				data: data
			};

			axios(config)
				.then(function (response) {
					Vue.$toast.open({
						message: response.data.message,
						position: "top-right",
						type: "success",
						duration: 3000,
						dismissible: false,
					});
				})
				.catch(function (error) {
					Vue.$toast.open({
						message: error.data.message,
						position: "top-right",
						type: "error",
						duration: 3000,
						dismissible: false,
					});
				});
		}
	},
	metaInfo() {
		return {
			title: this.meta.meta_name,
			meta: [
				{ name: 'description', vmid: "description", content: this.meta.meta_content },
				{ name: 'keywords', content: this.meta.meta_type },
				{ property: 'og:title', content: this.meta.meta_content },
				{ property: 'og:site_name', content: this.meta.meta_name },
				{ property: 'og:type', content: 'website' },
				{ name: 'robots', content: 'index,follow' }
			]
		}
	}
};
</script>

<style scoped>
.modal-mask {
	position: fixed;
	z-index: 9998;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .5);
	display: table;
	transition: opacity .3s ease;
}

.modal-wrapper {
	display: table-cell;
	vertical-align: middle;
}

.modal-header {
	padding: 5px 15px !important;
}

.slider-1 .swiper-slide {
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0 0 14px 0px #333333b0;
	height: 75px;
	margin: 20px 0;
	border-radius: 10px;
}

.slider-1 .swiper-slide div a {
	display: block;
}

.slider-1 .swiper-slide div a img {
	width: 100%;
	height: auto;
}

.slider-1 .swiper-wrapper {
	padding: 17px 0;
}

.recommended_for .recommend_box {
	text-align: center;
	border-radius: 5px;
	border: 1px solid #117a8e73;
	min-height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px;
	flex-direction: column;
}

.recommended_for .recommend_box h3 {
	font-size: 15px;
}

.basic-3 {
	padding-top: 0;
	padding-bottom: 6.125rem;
}

.singleChoose {
	box-shadow: 0 0 20px 0 #33333385;
	padding: 12px;
	border-radius: 25px;
	min-height: 235px;
	margin-top: 15px;
	border: 3px solid #ed1c24ad;
}

.welcome-right-image img {
	-webkit-animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
	-moz-animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
	-o-animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
	animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
}

.step_image_up {
	-webkit-animation: step_image_up_animation 4s linear infinite alternate;
	-moz-animation: step_image_up_animation 4s linear infinite alternate;
	-o-animation: step_image_up_animation 4s linear infinite alternate;
	animation: step_image_up_animation 4s linear infinite alternate;
}

.step_image_down {
	-webkit-animation: step_image_down_animation 4s linear infinite alternate;
	-moz-animation: step_image_down_animation 4s linear infinite alternate;
	-o-animation: step_image_down_animation 4s linear infinite alternate;
	animation: step_image_down_animation 4s linear infinite alternate;
}

@keyframes step_image_up_animation {
	0% {
		-webkit-transform: translateY(-85px);
		-moz-transform: translateY(-85px);
		-ms-transform: translateY(-85px);
		transform: translateY(-85px);
	}

	100% {
		-webkit-transform: translateY(0px);
		-moz-transform: translateY(0px);
		-ms-transform: translateY(0px);
		transform: translateY(0px);
	}
}

@keyframes step_image_down_animation {
	0% {
		-webkit-transform: translateY(85px);
		-moz-transform: translateY(85px);
		-ms-transform: translateY(85px);
		transform: translateY(85px);
	}

	100% {
		-webkit-transform: translateY(0px);
		-moz-transform: translateY(0px);
		-ms-transform: translateY(0px);
		transform: translateY(0px);
	}
}

@keyframes hero-thumb-sm-2-animation {
	0% {
		-webkit-transform: translateY(-50px);
		-moz-transform: translateY(-50px);
		-ms-transform: translateY(-50px);
		transform: translateY(-50px);
	}

	100% {
		-webkit-transform: translateY(0px);
		-moz-transform: translateY(0px);
		-ms-transform: translateY(0px);
		transform: translateY(0px);
	}
}
</style>
