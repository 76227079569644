<template>
	<!-- Navigation -->
	 <nav class="navbar navbar-expand-lg navbar-dark navbar-custom fixed-top">
        <router-link class="navbar-brand logo-image" to="/"><img src="https://readyeshop.com/images/logo.svg" alt="alternative"></router-link>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-awesome fas fa-bars"></span>
            <span class="navbar-toggler-awesome fas fa-times"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarsExampleDefault">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle page-scroll" href="javascript:void(0)" id="navbarDropdown" role="button" aria-haspopup="true" aria-expanded="false">Start</a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li><router-link class="dropdown-item" to="start-your-business">Start E-Shop</router-link>
                        <li><router-link class="dropdown-item" to="website-design">Website Design</router-link>
                        <li><router-link class="dropdown-item" to="website-hosting">Website Hosting</router-link>
                            <!-- <div class="dropdown-submenu">
								<li><router-link to="convert-your-domain">Domain</router-link></li>
                                <li><router-link to="create-your-own-brand">Branding</router-link></li>
                                <li><router-link to="company-slogan">Slogan</router-link></li>
                                <li><router-link to="site-logo">Logo</router-link></li>
                                <li><router-link to="social-links">Social Links</router-link></li>
                            </div> -->
                        </li>
                        <li><router-link class="dropdown-item" to="manage-product">Manage Product</router-link>
                            <!-- <div class="dropdown-submenu">
                                <li><router-link to="product-category">Product Category</router-link></li>
                                <li><router-link to="product-photography">Product Photography</router-link></li>
                                <li><router-link to="stock-photography">Stock Photography</router-link></li>
                            </div> -->
                        </li>
                        <li><router-link class="dropdown-item" to="sale-online">Sale Online</router-link>
                            <!-- <div class="dropdown-submenu">
                                <li><router-link to="sale-in-eshop">Sale in eShop</router-link></li>
                                <li><router-link to="market-place">Sale in MarketPlace</router-link></li>
                                <li><router-link to="point-of-sales">Offline Sale</router-link></li>
                            </div> -->
                        </li>
                    </div>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle page-scroll" href="javascript:void(0)" id="navbarDropdown" role="button" aria-haspopup="true" aria-expanded="false">Promotion</a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li><router-link class="dropdown-item" to="social-media">Social Media</router-link></li>
                        <li><router-link class="dropdown-item" to="sms-email">Sms & Email</router-link></li>
                        <li><router-link class="dropdown-item" to="share-product">Messanger</router-link></li>
                    </div>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle page-scroll" href="javascript:void(0)" id="navbarDropdown" role="button" aria-haspopup="true" aria-expanded="false">Manage</a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li><router-link class="dropdown-item" to="manage-your-eshop">Your eShop</router-link>
                           <!-- <div class="dropdown-submenu">
                                <li><router-link to="control-dashboard">Dashboard</router-link></li>
                                <li><router-link to="control-order">Order</router-link></li>
                                <li><router-link to="control-shipping">Shipping</router-link></li>
								<li><router-link to="control-payment">Payment</router-link></li>
                            </div> -->
                        </li>
                    </div>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link page-scroll" to="pricing">Pricing</router-link>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle page-scroll" href="javascript:void(0)" id="navbarDropdown" role="button" aria-haspopup="true" aria-expanded="false">Knowledge</a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li><a class="dropdown-item" href="https://blog.readyeshop.com/" target="_blank">Blog</a></li>
                        <li><router-link class="dropdown-item" to="news">News</router-link></li>
                        <li><router-link class="dropdown-item" to="free-tools">Free Tools</router-link></li>
                        <li><router-link class="dropdown-item" to="faq">Faq</router-link></li>
                    </div>
                </li>
                 <li class="nav-item">
                    <router-link class="btn btn-danger btn-sm trialBtn" to="try-free" style="background-color: #ed1c24; border-color: #ed1c24;">Free Trial</router-link>
                </li>
            </ul>
        </div>
    </nav> 
	<!-- end of navigation -->


	<!--Navbar-->
	<!-- <mdb-navbar class="mt-2 special-color-dark" dark>
		<mdb-navbar-brand class="text-white text-uppercase">
			<router-link class="navbar-brand logo-image" to="/"><img src="https://dev.readyeshop.com/dev.readyeshop.com/images/company/952361624970267.png" alt="alternative"></router-link>
		</mdb-navbar-brand>
		<mdb-navbar-toggler>
			<mdb-navbar-nav>
				<mdb-dropdown tag="li" megaMenu class="nav-item">
					<mdb-dropdown-toggle slot="toggle" class="text-uppercase" tag="a" transparent navLink waves-fixed>
						Features</mdb-dropdown-toggle>
					<mdb-dropdown-menu class="mega-menu v-2 z-depth-1 special-color py-5 px-3">
						<div class="row">
							<div class="col-md-6 col-xl-3 sub-menu mb-xl-0 mb-4">
								<h6 class="sub-title text-uppercase font-weight-bold white-text">Featured</h6>
								<ul class="list-unstyled">
									<li>
										<a class="menu-item pl-0" href="#!">
											<i class="fas fa-caret-right pl-1 pr-3 d-inline-block"></i>Lorem ipsum dolor
											sit amet
										</a>
									</li>
									<li>
										<a class="menu-item pl-0" href="#!">
											<i class="fas fa-caret-right pl-1 pr-3"></i>Consectetur adipiscing elit
										</a>
									</li>
									<li>
										<a class="menu-item pl-0" href="#!">
											<i class="fas fa-caret-right pl-1 pr-3"></i>Sed do eiusmod tempor incididunt
										</a>
									</li>
									<li>
										<a class="menu-item pl-0" href="#!">
											<i class="fas fa-caret-right pl-1 pr-3"></i>Ut labore et dolore magna
										</a>
									</li>
									<li>
										<a class="menu-item pl-0" href="#!">
											<i class="fas fa-caret-right pl-1 pr-3"></i>Ut enim ad minim veniam
										</a>
									</li>
								</ul>
							</div>
							<div class="col-md-6 col-xl-3 sub-menu mb-xl-0 mb-4">
								<h6 class="sub-title text-uppercase font-weight-bold white-text">Related</h6>
								<ul class="list-unstyled">
									<li>
										<a class="menu-item pl-0" href="#!">
											<i class="fas fa-caret-right pl-1 pr-3"></i>Quis nostrud exercitation
										</a>
									</li>
									<li>
										<a class="menu-item pl-0" href="#!">
											<i class="fas fa-caret-right pl-1 pr-3"></i>Duis aute irure dolor in
										</a>
									</li>
									<li>
										<a class="menu-item pl-0" href="#!">
											<i class="fas fa-caret-right pl-1 pr-3"></i>Laboris nisi ut aliquip ex ea
											commodo consequat
										</a>
									</li>
									<li>
										<a class="menu-item pl-0" href="#!">
											<i class="fas fa-caret-right pl-1 pr-3"></i>Reprehenderit in voluptate
										</a>
									</li>
									<li>
										<a class="menu-item pl-0" href="#!">
											<i class="fas fa-caret-right pl-1 pr-3"></i>Esse cillum dolore eu fugiat
											nulla pariatur
										</a>
									</li>
								</ul>
							</div>
							<div class="col-md-6 col-xl-3 sub-menu mb-md-0 mb-xl-0 mb-4">
								<h6 class="sub-title text-uppercase font-weight-bold white-text">Design</h6>
								<ul class="list-unstyled">
									<li>
										<a class="menu-item pl-0" href="#!">
											<i class="fas fa-caret-right pl-1 pr-3"></i>Excepteur sint occaecat
										</a>
									</li>
									<li>
										<a class="menu-item pl-0" href="#!">
											<i class="fas fa-caret-right pl-1 pr-3"></i>Sunt in culpa qui officia
										</a>
									</li>
									<li>
										<a class="menu-item pl-0" href="#!">
											<i class="fas fa-caret-right pl-1 pr-3"></i>Sed ut perspiciatis unde omnis
											iste natus error
										</a>
									</li>
									<li>
										<a class="menu-item pl-0" href="#!">
											<i class="fas fa-caret-right pl-1 pr-3"></i>Mollit anim id est laborum
										</a>
									</li>
									<li>
										<a class="menu-item pl-0" href="#!">
											<i class="fas fa-caret-right pl-1 pr-3"></i>Accusantium doloremque
											laudantium
										</a>
									</li>
								</ul>
							</div>
							<div class="col-md-6 col-xl-3 sub-menu mb-0">
								<h6 class="sub-title text-uppercase font-weight-bold white-text">Programming</h6>
								<ul class="list-unstyled">
									<li>
										<a class="menu-item pl-0" href="#!">
											<i class="fas fa-caret-right pl-1 pr-3"></i>Totam rem aperiam eaque
										</a>
									</li>
									<li>
										<a class="menu-item pl-0" href="#!">
											<i class="fas fa-caret-right pl-1 pr-3"></i>Beatae vitae dicta sun
										</a>
									</li>
									<li>
										<a class="menu-item pl-0" href="#!">
											<i class="fas fa-caret-right pl-1 pr-3"></i>Quae ab illo inventore veritatis
											et quasi architecto
										</a>
									</li>
									<li>
										<a class="menu-item pl-0" href="#!">
											<i class="fas fa-caret-right pl-1 pr-3"></i>Nemo enim ipsam voluptatem
										</a>
									</li>
									<li>
										<a class="menu-item pl-0" href="#!">
											<i class="fas fa-caret-right pl-1 pr-3"></i>Neque porro quisquam est
										</a>
									</li>
								</ul>
							</div>
						</div>
					</mdb-dropdown-menu>
				</mdb-dropdown>
				<mdb-dropdown tag="li" megaMenu class="nav-item">
					<mdb-dropdown-toggle slot="toggle" class="text-uppercase" tag="a" transparent navLink waves-fixed>
						Technology</mdb-dropdown-toggle>
					<mdb-dropdown-menu class="mega-menu v-2 z-depth-1 special-color py-5 px-3">
						<div class="row">
							<div class="col-md-12 col-xl-4 sub-menu mb-xl-0 mb-4">
								<h6 class="sub-title text-uppercase font-weight-bold white-text">Featured</h6>
								<a href="#!" class="view overlay z-depth-1 p-0 mb-2">
									<img src="https://mdbootstrap.com/img/Photos/Horizontal/Work/8-col/img%20(37).jpg"
										class="img-fluid" alt="First sample image">
									<div class="mask rgba-white-slight"></div>
								</a>
								<a class="news-title font-weight-bold pl-0" href="#!">Lorem ipsum dolor sit</a>
								<p class="font-small text-uppercase white-text">
									<i class="far fa-clock pr-2" aria-hidden="true"></i>July 17, 2017 / <i
										class="fas fa-comments-o px-1" aria-hidden="true"></i> 20
								</p>
							</div>
							<div class="col-md-6 col-xl-4 sub-menu mb-md-0 mb-4">
								<h6 class="sub-title text-uppercase font-weight-bold white-text">Related</h6>
								<ul class="list-unstyled">
									<li>
										<a class="menu-item pl-0" href="#!">
											<i class="fas fa-caret-right pl-1 pr-3"></i>Quis nostrud exercitation
										</a>
									</li>
									<li>
										<a class="menu-item pl-0" href="#!">
											<i class="fas fa-caret-right pl-1 pr-3"></i>Duis aute irure dolor in
										</a>
									</li>
									<li>
										<a class="menu-item pl-0" href="#!">
											<i class="fas fa-caret-right pl-1 pr-3"></i>Laboris nisi ut aliquip ex ea
											commodo consequat
										</a>
									</li>
									<li>
										<a class="menu-item pl-0" href="#!">
											<i class="fas fa-caret-right pl-1 pr-3"></i>Reprehenderit in voluptate
										</a>
									</li>
									<li>
										<a class="menu-item pl-0" href="#!">
											<i class="fas fa-caret-right pl-1 pr-3"></i>Esse cillum dolore eu fugiat
											nulla pariatur
										</a>
									</li>
								</ul>
							</div>
							<div class="col-md-6 col-xl-4 sub-menu mb-0">
								<h6 class="sub-title text-uppercase font-weight-bold white-text">Design</h6>
								<ul class="list-unstyled">
									<li>
										<a class="menu-item pl-0" href="#!">
											<i class="fas fa-caret-right pl-1 pr-3"></i>Excepteur sint occaecat
										</a>
									</li>
									<li>
										<a class="menu-item pl-0" href="#!">
											<i class="fas fa-caret-right pl-1 pr-3"></i>Sunt in culpa qui officia
										</a>
									</li>
									<li>
										<a class="menu-item pl-0" href="#!">
											<i class="fas fa-caret-right pl-1 pr-3"></i>Sed ut perspiciatis unde omnis
											iste natus error
										</a>
									</li>
									<li>
										<a class="menu-item pl-0" href="#!">
											<i class="fas fa-caret-right pl-1 pr-3"></i>Mollit anim id est laborum
										</a>
									</li>
									<li>
										<a class="menu-item pl-0" href="#!">
											<i class="fas fa-caret-right pl-1 pr-3"></i>Accusantium doloremque
											laudantium
										</a>
									</li>
								</ul>
							</div>
						</div>
					</mdb-dropdown-menu>
				</mdb-dropdown>
				<mdb-dropdown tag="li" megaMenu class="nav-item">
					<mdb-dropdown-toggle slot="toggle" class="text-uppercase" tag="a" transparent navLink waves-fixed>
						Lifestyle</mdb-dropdown-toggle>
					<mdb-dropdown-menu class="mega-menu v-2 z-depth-1 special-color py-5 px-3">
						<div class="row">
							<div class="col-md-6 col-xl-3 sub-menu mb-4">
								<h6 class="sub-title text-uppercase font-weight-bold white-text">Featured</h6>
								<ul class="list-unstyled">
									<li>
										<a class="menu-item pl-0" href="#!">
											<i class="fas fa-caret-right pl-1 pr-3"></i>Lorem ipsum dolor sit amet
										</a>
									</li>
									<li>
										<a class="menu-item pl-0" href="#!">
											<i class="fas fa-caret-right pl-1 pr-3"></i>Consectetur adipiscing elit
										</a>
									</li>
									<li>
										<a class="menu-item pl-0" href="#!">
											<i class="fas fa-caret-right pl-1 pr-3"></i>Sed do eiusmod tempor incididunt
										</a>
									</li>
									<li>
										<a class="menu-item pl-0" href="#!">
											<i class="fas fa-caret-right pl-1 pr-3"></i>Ut labore et dolore magna
										</a>
									</li>
									<li>
										<a class="menu-item pl-0" href="#!">
											<i class="fas fa-caret-right pl-1 pr-3"></i>Ut enim ad minim veniam
										</a>
									</li>
								</ul>
							</div>
							<div class="col-md-6 col-xl-3 sub-menu mb-4 mb-xl-0">
								<h6 class="sub-title text-uppercase font-weight-bold white-text">Related</h6>
								<a href="#!" class="view overlay z-depth-1 p-0 mb-2">
									<img src="https://mdbootstrap.com/img/Photos/Horizontal/Work/6-col/img%20(43).jpg"
										class="img-fluid" alt="First sample image">
									<div class="mask rgba-white-slight"></div>
								</a>
								<a class="news-title font-weight-bold pl-0" href="#!">Lorem ipsum dolor sit</a>
								<p class="font-small text-uppercase white-text">
									<i class="far fa-clock pr-2" aria-hidden="true"></i>July 17, 2017 / <i
										class="fas fa-comments-o px-1" aria-hidden="true"></i> 20
								</p>
							</div>
							<div class="col-md-6 col-xl-3 sub-menu mb-md-0 mb-xl-0 mb-4">
								<h6 class="sub-title text-uppercase font-weight-bold white-text">Design</h6>
								<a href="#!" class="view overlay z-depth-1 p-0 mb-2">
									<img src="https://mdbootstrap.com/img/Photos/Horizontal/Work/6-col/img%20(44).jpg"
										class="img-fluid" alt="First sample image">
									<div class="mask rgba-white-slight"></div>
								</a>
								<a class="news-title font-weight-bold pl-0" href="#!">Ut labore et dolore magna</a>
								<p class="font-small text-uppercase white-text">
									<i class="far fa-clock pr-2" aria-hidden="true"></i>July 16, 2017 / <i
										class="fas fa-comments-o px-1" aria-hidden="true"></i> 25
								</p>
							</div>
							<div class="col-md-6 col-xl-3 sub-menu mb-0">
								<h6 class="sub-title text-uppercase font-weight-bold white-text">Programming</h6>
								<ul class="list-unstyled">
									<li>
										<a class="menu-item pl-0" href="#!">
											<i class="fas fa-caret-right pl-1 pr-3"></i>Totam rem aperiam eaque
										</a>
									</li>
									<li>
										<a class="menu-item pl-0" href="#!">
											<i class="fas fa-caret-right pl-1 pr-3"></i>Beatae vitae dicta sun
										</a>
									</li>
									<li>
										<a class="menu-item pl-0" href="#!">
											<i class="fas fa-caret-right pl-1 pr-3"></i>Quae ab illo inventore veritatis
											et quasi architecto
										</a>
									</li>
									<li>
										<a class="menu-item pl-0" href="#!">
											<i class="fas fa-caret-right pl-1 pr-3"></i>Nemo enim ipsam voluptatem
										</a>
									</li>
									<li>
										<a class="menu-item pl-0" href="#!">
											<i class="fas fa-caret-right pl-1 pr-3"></i>Neque porro quisquam est
										</a>
									</li>
								</ul>
							</div>
						</div>
					</mdb-dropdown-menu>
				</mdb-dropdown>
			</mdb-navbar-nav>
		</mdb-navbar-toggler>
	</mdb-navbar> -->
	<!--/.Navbar-->
</template>
<script>
	import {
		mdbContainer,
		mdbRow,
		mdbIcon,
		mdbNavbar,
		mdbNavbarBrand,
		mdbNavbarToggler,
		mdbNavbarNav,
		mdbNavItem,
		mdbInput,
		mdbDropdown,
		mdbDropdownToggle,
		mdbDropdownMenu,
		mdbDropdownItem
	} from 'mdbvue';
	// import "mdbvue/lib/css/mdb.min.css";
	export default {
		data() {
			return {}
		},
		name: 'MegaMenuPage',
		components: {
			mdbContainer,
			mdbRow,
			mdbIcon,
			mdbNavbar,
			mdbNavbarBrand,
			mdbNavbarToggler,
			mdbNavbarNav,
			mdbNavItem,
			mdbInput,
			mdbDropdown,
			mdbDropdownToggle,
			mdbDropdownMenu,
			mdbDropdownItem
		},
		created() {

		},
		methods: {

		}
	}
</script>
<style>
	.mega-menu.dropdown-menu {
	    width: 1000px;
	}
</style>