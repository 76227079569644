window.Vue = require('vue').default;

import Meta from 'vue-meta';
import VueRouter from 'vue-router';
import myMixins from '../frontend/mixins';


import App from './App.vue';
import { routes } from './routes';
import store from './store';

Vue.use(VueRouter)
Vue.use(Meta);


const router = new VueRouter({
    mode: 'history',
    routes: routes,
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
});


const app = new Vue({
    el: '#app',
    data: { },
    mixins: [myMixins],
    router: router,
    store,
    render: h => h(App),
});
