
import Home from "./pages/Home";


export const routes = [
    {
        name: 'website-design-details',
        path: '/website-design-details',
        component: () => import(/* webpackChunkName: "website-design-details" */ './pages/WebsiteDesignDetails')
    }, 
    {
        name: 'website-hosting-details',
        path: '/website-hosting-details',
        component: () => import(/* webpackChunkName: "website-hosting-details" */ './pages/WebsiteHostingDetails')
    }, 
    {
        name: 'contact',
        path: '/contact',
        component: () => import(/* webpackChunkName: "contact" */ './pages/Contact')
    }, 
    {
        name: 'manage-your-eshop',
        path: '/manage-your-eshop',
        component: () => import(/* webpackChunkName: "manage-your-eshop" */ './pages/ManageYourEshop')
    }, 
    {
        name: 'sale-in-eshop',
        path: '/sale-in-eshop',
        component: () => import(/* webpackChunkName: "sale-in-eshop" */ './pages/SaleInShop')
    }, 
    {
        name: 'about',
        path: '/about',
        component: () => import(/* webpackChunkName: "about" */ './pages/About')
    }, 
    {
        name: 'news-details',
        path: '/news-details',
        component: () => import(/* webpackChunkName: "news-details" */ './pages/NewsDetails')
    }, 
    {
        name: 'try-free',
        path: '/try-free',
        component: () => import(/* webpackChunkName: "try-free" */ './pages/TryFree')
    }, 
    {
        name: 'website-design',
        path: '/website-design',
        component: () => import(/* webpackChunkName: "website-design" */ './pages/WebsiteDesign')
    }, 
    {
        name: 'website-hosting',
        path: '/website-hosting',
        component: () => import(/* webpackChunkName: "website-hosting" */ './pages/WebsiteHosting')
    }, 
    {
        name: 'clients',
        path: '/clients',
        component: () => import(/* webpackChunkName: "clients" */ './pages/Clients')
    }, 
    {
        name: 'features',
        path: '/features',
        component: () => import(/* webpackChunkName: "features" */ './pages/Features')
    }, 
    {
        name: 'site-logo',
        path: '/site-logo',
        component: () => import(/* webpackChunkName: "site-logo" */ './pages/SiteLogo')
    }, 
    {
        name: 'social-links',
        path: '/social-links',
        component: () => import(/* webpackChunkName: "social-links" */ './pages/SocialLinks')
    }, 
    {
        name: 'free-tools',
        path: '/free-tools',
        component: () => import(/* webpackChunkName: "free-tools" */ './pages/FreeTools')
    }, 
    {
        name: 'faq',
        path: '/faq',
        component: () => import(/* webpackChunkName: "faq" */ './pages/Faq')
    }, 
    {
        name: 'news',
        path: '/news',
        component: () => import(/* webpackChunkName: "news" */ './pages/News')
    }, 
    {
        name: 'help-desk',
        path: '/help-desk',
        component: () => import(/* webpackChunkName: "help-desk" */ './pages/HelpDesk')
    }, 
    {
        name: 'create-your-own-brand',
        path: '/create-your-own-brand',
        component: () => import(/* webpackChunkName: "create-your-own-brand" */ './pages/CreateYourOwnBrand')
    }, 
    {
        name: 'knowledge',
        path: '/knowledge',
        component: () => import(/* webpackChunkName: "knowledge" */ './pages/Knowledge')
    }, 
    {
        name: 'pricing',
        path: '/pricing',
        component: () => import(/* webpackChunkName: "pricing" */ './pages/Pricing')
    }, 
    {
        name: 'control-dashboard',
        path: '/control-dashboard',
        component: () => import(/* webpackChunkName: "control-dashboard" */ './pages/ControlDashboard')
    }, 
    {
        name: 'control-order',
        path: '/control-order',
        component: () => import(/* webpackChunkName: "control-order" */ './pages/ControlOrder')
    }, 
    {
        name: 'control-shipping',
        path: '/control-shipping',
        component: () => import(/* webpackChunkName: "control-shipping" */ './pages/ControlShipping')
    }, 
    {
        name: 'control-payment',
        path: '/control-payment',
        component: () => import(/* webpackChunkName: "control-payment" */ './pages/ControlPayment')
    }, 
    {
        name: 'company-slogan',
        path: '/company-slogan',
        component: () => import(/* webpackChunkName: "company-slogan" */ './pages/SompanySlogan')
    }, 
    {
        name: 'product-category',
        path: '/product-category',
        component: () => import(/* webpackChunkName: "product-category" */ './pages/ProductCategory')
    }, 
    {
        name: 'product-photography',
        path: '/product-photography',
        component: () => import(/* webpackChunkName: "product-photography" */ './pages/ProductPhotography')
    }, 
    {
        name: 'stock-photography',
        path: '/stock-photography',
        component: () => import(/* webpackChunkName: "stock-photography" */ './pages/StockPhotography')
    }, 
    {
        name: 'start-your-business',
        path: '/start-your-business',
        component: () => import(/* webpackChunkName: "start-your-business" */ './pages/StartAbusiness')
    }, 
    {
        name: 'manage-product',
        path: '/manage-product',
        component: () => import(/* webpackChunkName: "manage-product" */ './pages/ManageProduct')
    }, 
    {
        name: 'sale-online',
        path: '/sale-online',
        component: () => import(/* webpackChunkName: "sale-online" */ './pages/SaleOnline')
    }, 
    {
        name: 'facebook-instagram-shop',
        path: '/facebook-instagram-shop',
        component: () => import(/* webpackChunkName: "facebook-instagram-shop" */ './pages/FacebookInstagramShop')
    }, 
    {
        name: 'camping',
        path: '/camping',
        component: () => import(/* webpackChunkName: "camping" */ './pages/Camping')
    }, 
    {
        name: 'point-of-sales',
        path: '/point-of-sales',
        component: () => import(/* webpackChunkName: "point-of-sales" */ './pages/PointOfSales')
    }, 
    {
        name: 'market-place',
        path: '/market-place',
        component: () => import(/* webpackChunkName: "market-place" */ './pages/MarketPlace')
    }, 
    {
        name: 'convert-your-domain',
        path: '/convert-your-domain',
        component: () => import(/* webpackChunkName: "convert-your-domain" */ './pages/ConvertYourDomain')
    }, 
    {
        name: 'social-media',
        path: '/social-media',
        component: () => import(/* webpackChunkName: "social-media" */ './pages/CampFacebookInstagram')
    }, 
    {
        name: 'sms-email',
        path: '/sms-email',
        component: () => import(/* webpackChunkName: "sms-email" */ './pages/CampSmsEmail')
    }, 
    {
        name: 'share-product',
        path: '/share-product',
        component: () => import(/* webpackChunkName: "share-product" */ './pages/CampShareProduct')
    }, 
    {
        name: 'home',
        path: '/',
        component: Home
    }, 
];

